const translations = {
  errors: {
    base: {
      startTimeBeforeEndTime: "The start time must be after the end time."
    },
    description: {
      required: "Event details are required."
    },
    endTime: {
      required: "An end time is required."
    },
    eventType: {
      oneOf: "The selected event type is invalid. Please select a valid type.",
      required: "Please select an event type."
    },
    startTime: {
      required: "A start time is required."
    },
    status: {
      oneOf: "The selected status is invalid. Please select a valid status.",
      required: "Please select a status for this event."
    },
    summary: {
      required: "A title is required."
    },
    transparent: {
      required: "Please specify if this time should remain available for booking."
    }
  },
  labels: {
    description: "Event details",
    endTime: "End time",
    eventType: "Event type",
    startTime: "Start time",
    status: "Event status",
    summary: "Event title",
    transparent: "Keep your time available for booking over this event"
  },
  options: {
    eventType: {
      birthday: "Birthday",
      default: "Default",
      focusTime: "Focus time",
      fromGmail: "From Gmail",
      outOfOffice: "Out of office",
      workingLocation: "Working location"
    },
    status: {
      // the two L version comes from google, so we can't change the key here.
      cancelled: "Canceled",
      confirmed: "Confirmed",
      tentative: "Tentative"
    }
  }
};

export default translations;
